import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useWindowSize } from 'react-use';

import { getCurrentBreakpoint } from '@@src/styles/breakpoints';
import { getTileSpecV2 } from '@@src/utils/helpers';

import TileSlider from '../Carousel/TileSlider';
import { createPlaceholderTiles } from '../Tiles/PlaceholderTile';
import OdContainer from '../Utils/OdContainer';
import { sharedStyle } from './ShelfShell';

const useStyles = makeStyles(sharedStyle);

type PlaceholderShelfProps = {
  collectionTitle: string;
  collectionDisplayType: '2:3' | '16:9' | 'list';
};

const PlaceholderShelf: FunctionComponent<PlaceholderShelfProps> = (props) => {
  const {
    collectionTitle,
    collectionDisplayType,
  } = props;
  const breakpoint = getCurrentBreakpoint();
  const { width: windowWidth } = useWindowSize();
  const tileSpec = getTileSpecV2(collectionDisplayType, windowWidth, breakpoint);

  const classes = useStyles(props);
  return (
    <div className={classes.shelf}>
      <OdContainer>
        <Typography variant="h2" component="h2" className={classes.shelfName}>
          {collectionTitle}
        </Typography>
      </OdContainer>
      <TileSlider
        tiles={createPlaceholderTiles(tileSpec.imageHeight, tileSpec.itemsPerView, `shelf-placeholder-${collectionTitle}`, false)}
        tileSpec={tileSpec}
        hasActiveState={false}
      />
    </div>
  );
};

export default PlaceholderShelf;
