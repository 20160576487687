import httpClient, { handleHttpError, httpClientWithAuth } from '@@utils/HttpClient';

import OnDemand from '../@types/OnDemand';
import { hasSessionId } from '../components/Login/LoginForm';

export declare namespace FeedItem {

  interface DisplayTitleDouble {
    title: string;
    subtitle: string;
  }

  interface DisplayTitleHero {
    caption: string;
    title: string;
    description: string;
  }

  interface DisplayTitles {
    double?: DisplayTitleDouble;
    hero?: DisplayTitleHero;
    episodeTitle?: string;
    appleumc?: any;
    programpage?: any;
    seasonListTitle?: any;
    seasonlist?: any;
    videoPlayer?: any;
    episodePromotion?: {
      title: string;
      subtitle: string;
    };
    title?: string;
    subtitle?: string;
    pageTitle?: string;
    components?: any;
    _debug?: any;
  }

  interface PartOfSeries {
    name: string;
    id: string;
    slug: string;
  }

  interface PartOfSeason {
    seasonNumber: string;
  }

  interface TaxonomyTerm {
    type: string;
    name: string;
  }

  type UseType = 'Full Episode' | 'Feature Story' | 'Live Stream' | 'Trailer';

  interface Taxonomy {
    useType?: UseType;
    genre?: TaxonomyTerm[];
    subGenre?: TaxonomyTerm[];
    era?: TaxonomyTerm[];
    location?: TaxonomyTerm[];
    theme?: TaxonomyTerm[];
    tone?: TaxonomyTerm[];
    channel?: TaxonomyTerm[];
    collection?: TaxonomyTerm[];
    section?: TaxonomyTerm[];
    subject?: TaxonomyTerm[];
    place?: (string | TaxonomyTerm)[];
    grouping?: TaxonomyTerm[];
    organisation?: TaxonomyTerm[];
  }

  interface Pilat {
    id: string;
  }

  interface ExternalRelations {
    appleUniversalSearch?: any;
    mpx?: {
      guid: number | string;
    };
    pilat?: {
      deal?: Pilat;
      deals?: Pilat[];
    };
    relatedLinks?: {
      name: string;
      url: string;
    }[];
    roku?: any;
    sbsOnDemandUrl?: string;
    sbsondemand?: {
      entity_type: string;
    };
  }

  interface Offer {
    availabilityStarts: string;
    availabilityEnds: string;
  }

  interface Thumbnail {
    name: string;
    encodingFormat: string;
    contentUrl: string;
    width?: number;
    height?: number;
  }

  interface Base {
    id: string;
    actor: Record<string, unknown>[];
    contentRating?: string;
    consumerAdvice?: string[];
    country?: {
      name: string;
    };
    description: string;
    descriptionHtml?: string;
    shortDescription?: string;
    shortDescriptionHtml?: string;
    director: Record<string, unknown>[];
    externalRelations?: ExternalRelations;
    inLanguage?: {
      type: string;
      name: string;
    }[];
    name: string;
    slug?: string;
    thumbnails: Thumbnail[];
    thumbnailUrl: string;
    taxonomy: Taxonomy;
    visualAids?: {
      subtitles: string[];
      closedCaptions: string[];
    };
    videoFormat: 'SD' | 'HD';
    trailers?: {
      id: string;
      type: 'Movie';
    }[];
    keywords: string[];
    pilat?: {
      id: string;
    };
    award: string;
    sbsSubCertification?: OnDemand.SbsSubCertification;
  }

  interface VideoBase extends Base {
    consumerAdvice?: string[];
    datePublished?: string;
    displayTitles?: DisplayTitles;
    duration?: number;
    inStreamEvents?: {
      [key: string]: any;
    };
    offer: Offer;
    pilat?: Pilat;
    publication?: {
      type: string;
      startDate: string;
    };
    requiresSubscription?: boolean;
    revision?: any;
    shortDescription?: string;
    shortDescriptionHtml?: string;
  }

  interface Clip extends VideoBase {
    type: 'Clip';
    partOfSeries?: PartOfSeries;
  }

  interface Trailer extends VideoBase {
    type: 'Clip';
    taxonomy: Taxonomy & {
      useType: 'Trailer';
    };
  }

  interface OneOff extends VideoBase {
    type: 'OneOff';
  }

  interface Movie extends VideoBase {
    type: 'Movie';
  }

  interface Episode extends VideoBase {
    type: 'Episode';
    episodeNumber: number;
    partOfSeries: PartOfSeries;
    partOfSeason?: PartOfSeason;
  }

  interface Collection extends VideoBase {
    type: 'Collection';
    feedUrl: string;
    name: string;
    slug: string;
    description: string;
    thumbnails: Thumbnail[];
    audio: any;
    numberOfItems: number;
    itemBreakDown: any;
    ondemandUri: string;
    websiteUrl: string;
  }

  type Video = Clip | OneOff | Movie | Episode;

  interface TvSeries extends Base {
    type: 'TVSeries';
    contentAdvisory?: string[];
    announcementText?: string;
    containSeasons?: {
      externalRelations?: ExternalRelations;
      feed?: string;
      name?: string;
      numberOfEpisodes?: number;
      seasonNumber?: number;
      type?: string;
      offer?: Offer;
    }[];
    displayTitles?: {
      hero?: DisplayTitleHero;
    };
    isSerialised: number;
    episodesAvailable: number;
    feed: string;
    featuredEpisode?: {
      id: string;
      type: 'Episode';
    };
    featuredEpisodeData?: Episode;
    availability: {
      availabilityStarts: string;
      availabilityEnds: string;
    };
  }

  interface Unknown extends Base {
    type: 'Unknown';
  }

  type Any = TvSeries | Clip | Movie | Episode | OneOff | Collection | Unknown;
}

export declare namespace FeedApi {
  interface Response {
    '@context': any;
    type: string;
    name: string;
    itemListElement: FeedItem.Any[];
    numberOfItems: number;
    totalNumberOfItems: number;
  }
}

function fetch(feedUrl: string, requireLogin: boolean = false, rangeFrom: number = 1, rangeTo: number = 10): Promise<FeedApi.Response> {
  const options = {
    params: {
      range: `${rangeFrom}-${rangeTo}`,
    },
  };

  // if feed api requires login but there is no session token, don't make the request and return null
  if (requireLogin && !hasSessionId()) {
    return Promise.resolve(null);
  }

  const _httpClient = requireLogin ? httpClientWithAuth : httpClient;

  return _httpClient.get<FeedApi.Response>(feedUrl, options)
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      return handleHttpError(
        e,
        new Error('Error on FeedApi.fetch()'),
        {},
        [404],
        null,
        'warn',
      );
    });
}

export default fetch;
