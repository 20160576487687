import { Box, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import {
  cloneElement,
  FunctionComponent,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';

import OnDemand2 from '@@src/@types/OnDemand2';
import VideoProgressBar from '@@src/components/ProgressBar/VideoProgressBar';
import CollectionTile from '@@src/components/Tiles/TilesV2/CollectionTile';
import PageTile from '@@src/components/Tiles/TilesV2/PageTile';
import SeriesTile from '@@src/components/Tiles/TilesV2/SeriesTile';
import VideoTile from '@@src/components/Tiles/TilesV2/VideoTile';
import { OdLink } from '@@src/routes';
import blue from '@@styles/colors/blue';
import grey from '@@styles/colors/grey';
import fontFamily from '@@styles/typography/fontFamily';
import OnDemand from '@@types/OnDemand';
import { getTileGrowRatio } from '@@utils/helpers';

import { ReactComponent as PlayIcon } from '../../../images/icons/play-circle.svg';
import Image from '../../Html/Image';

export const tileAnimationConfig = {
  transitionDuration: 400,
};

export const useTileStyles = makeStyles((theme: Theme) => {
  // transition is ease-out-cubic https://easings.net/#easeOutCubic
  const animationTransition = `${tileAnimationConfig.transitionDuration}ms all cubic-bezier(0.33, 1, 0.68, 1), visibility 0s`;

  return createStyles({
    root: {
      position: 'relative',
    },
    tile: {
      borderRadius: 0,
      position: 'relative',
      width: '100%',
      top: 0,
      left: 0,
    },
    link: {
      display: 'block',
      '&:focus:not(.active)[data-focus-visible-added]': {
        outline: `3px solid ${blue.navy}`,
      },
      '&[data-focus-visible-added]': {
        '&.active': {
          '& $imageContainer': {
            outlineOffset: '-1.5px',
            outline: `3px solid ${blue.navy}`,
          },
        },
      },
    },
    tileContent: {
      padding: '10px',
      borderRadius: 4,
      [theme.breakpoints.down('xs')]: {
        padding: '8px',
      },
      textAlign: 'left',
    },
    tileTitle: {
      overflow: 'hidden',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      lineHeight: 1.125,
      '& + div': {
        marginTop: 4,
      },
      fontFamily: fontFamily.roboto,
    },
    subtitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: grey.chateau,
      display: 'flex',
      fontSize: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8125rem',
      },
      lineHeight: 1.2,
      '& > span:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      minHeight: '1.2em',
      paddingTop: 2,
      fontFamily: fontFamily.primary,
    },
    tileMoreContent: {
      position: 'absolute',
      zIndex: 2,
      opacity: 0,
      visibility: 'hidden',
      padding: '0 24px 24px',
      transition: animationTransition,
      textAlign: 'left',
      bottom: 0,
      left: 0,
      transformOrigin: 'bottom left',
      '.active &': {
        opacity: 1,
        visibility: 'visible',
      },
      '&.hasProgress': {
        paddingBottom: 32,
      },
    },
    'tileMoreContent-16:9': {
      transform: `scale(calc(100%/${getTileGrowRatio(16 / 9)}))`,
      width: `calc(100% * ${getTileGrowRatio(16 / 9)})`,
    },
    'tileMoreContent-2:3': {
      transform: `scale(calc(100%/${getTileGrowRatio(2 / 3)}))`,
      width: `calc(100% * ${getTileGrowRatio(2 / 3)})`,
    },
    tileMoreContentTitle: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.2,
      [theme.breakpoints.down('md')]: {
        // so it doesn't overlap with the Favourites icon
        width: '90%',
      },
    },
    withPlayIcon: {
      display: 'flex',
      alignItems: 'center',
      '& $metadata': {
        paddingTop: '8px',
      },
    },
    playButtonContainer: {
      display: 'inline-flex',
      marginRight: 16,
      fontSize: '3.3rem',
      padding: 0,
    },
    metadata: {
      paddingTop: '12px',
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      fontFamily: fontFamily.primary,
      columnGap: 20,
      lineHeight: 'normal',
      '& ul': {
        display: 'flex',
        flexWrap: 'wrap',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        color: grey.chateau,
        fontSize: '1rem',
        fontWeight: 500,
        '& li': {
          display: 'inline-block',
          textWrap: 'wrap',
          '& + li:before': {
            content: '"•"',
            fontSize: '1rem',
            fontWeight: 500,
            padding: '0 10px 0 10px',
          },
          '& span': {
            marginBottom: '1px',
            verticalAlign: 'middle',
          },
        },
      },
    },
    classification: {
      marginTop: 3,
    },
    description: {
      lineHeight: '1.5em',
      maxHeight: '5em',
      fontSize: '1rem',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      marginTop: 16,
      paddingBottom: 0,
    },
    'description-2:3': {
      maxHeight: '9em',
      '-webkit-line-clamp': 6,
    },
    imageContainer: {
      backgroundColor: grey.blackpearl,
      position: 'relative',
      transition: '.5s all',
      transitionDelay: '0.5s',
      border: `1px solid ${grey.darkBlackPearl}`,
      borderRadius: 4,
      overflow: 'hidden',
      height: 0,
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '.active &': {
          filter: 'blur(3px)',
        },
      },
      '&:after': {
        content: '""',
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        width: '100%',
        opacity: 0,
        transition: animationTransition,
        zIndex: 1,
      },
      '.active &': {
        '&:after': {
          opacity: 1,
        },
      },
    },
    // force image to render in 16:9 aspect ratio if it comes back from the Image API in a different aspect ratio because the source image is smaller than the target resolution
    // NOTE: this can be removed if the Image API can always return the target resolution regardless of the source
    'imageContainer-16:9': {
      paddingTop: '55.25%',
      '.active &': {
        '&:after': {
          // apply scaling to gradient to minimise the pixel gap that renders on Chrome
          transform: 'scale(1.05)',
        },
      },
      '&:after': {
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 48%, rgb(0, 0, 0) 100%)',
      },
    },
    'imageContainer-2:3': {
      paddingTop: '150%',
      '&:after': {
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 48%, rgb(0, 0, 0) 100%)',
      },
    },
    activableContent: {
      transition: animationTransition,
      position: 'relative',
    },
    'activableContent-16_9': {
      '.active &': {
        transform: `scale(${getTileGrowRatio(16 / 9)})`,
      },
    },
    'activableContent-2_3': {
      '.active &': {
        transform: `scale(${getTileGrowRatio(2 / 3)})`,
      },
    },
    screenReaderOnly: {
      position: 'absolute',
      left: '10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
    },
    ctaButton: {
      position: 'absolute',
      zIndex: 3,
      top: 12.5,
      right: 12.5,
      visibility: 'hidden',
      opacity: 0,
      transition: animationTransition,
      '$activableContent-2_3 &': {
        // The CTA button should double it size between inactive and active state.
        // Since the tile is only scaling up by less than 2, the button needs to scale up by the remaining (ie: (2 - tile scale) + 1)
        transform: `scale(${3 - getTileGrowRatio(2 / 3)})`,
      },
      '$activableContent-16_9 &': {
        transform: `scale(${3 - getTileGrowRatio(16 / 9)})`,
      },
      '.active &': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    statusBadge: {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      position: 'absolute',
      zIndex: 3,
      top: 1,
      left: 1,
      padding: '3px 20px 4px 10px',
      borderRadius: '2px 0 18px 0',
      '.active &': {
        transformOrigin: 'top left',
        transform: `scale(calc(100%/${getTileGrowRatio(16 / 9)}))`,
      },
    },
    progressBarContainer: {
      padding: '0 2px 0 2px',
      bottom: 2,
    },
    progressBar: {
      borderRadius: '0 0 4px 4px',
      height: 10,
    },
    progressBarFill: {
      borderRadius: '0 0 0px 4px',
      height: 10,
      '&.completed': {
        borderRadius: '0 0 4px 4px',
      },
    },
  });
});

interface TileMoreContentProps extends OnDemand.BaseTile {
  isActive: boolean;
}

const TileMoreContent: FunctionComponent<TileMoreContentProps> = (props) => {
  const {
    title, displayType, description, playIcon = false, metadata, classification,
    isActive = false, videoIdForProgress,
  } = props;
  const classes = useTileStyles(props);

  const metadataContent = (metadata || classification) && (
    <div className={classes.metadata}>
      {metadata}
    </div>
  );

  return (
    <div
      className={clsx(classes[`tileMoreContent-${displayType}`], classes.tileMoreContent, 'prefers-reduced-motion', { hasProgress: !!videoIdForProgress })}
      aria-hidden={!isActive}
    >
      <div>
        {playIcon
          ? (
            <div className={classes.withPlayIcon}>
              <div className={classes.playButtonContainer} data-testid="playIcon">
                <PlayIcon/>
              </div>
              <div>
                <span className={classes.screenReaderOnly}>Play&nbsp;</span>
                <Typography variant="body1" component="div" className={classes.tileMoreContentTitle}>
                  {title}
                </Typography>
                {metadataContent}
              </div>
            </div>
          )
          : (
            <div>
              <Typography variant="body1" component="div" className={classes.tileMoreContentTitle}>
                {title}
              </Typography>
              {metadataContent}
            </div>
          )}
        <Typography variant="body1" component="p" className={clsx(classes.description, classes[`description-${displayType}`])}>
          {description}
        </Typography>
      </div>
      {videoIdForProgress && (
        <VideoProgressBar
          classes={{
            root: classes.progressBarContainer,
            progressBar: classes.progressBar,
            progressBarFill: classes.progressBarFill,
          }}
          videoId={videoIdForProgress}
          position="bottom"
          transparent
        />
      )}
    </div>
  );
};

const BaseTile: FunctionComponent<OnDemand.BaseTile> = (props) => {
  const {
    displayType, title, subtitle, link, imageId, imageSrc,
    ctaButton, statusBadge, onActivate, onDeactivate, canBeFocused = true,
    onClick, playIcon = false,
  } = props;
  const classes = useTileStyles(props);
  const [isActive, setIsActive] = useState<boolean>(false);
  const theme = useTheme();
  const mediaMatchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const tileRef = useRef();

  const imageProps = {
    ...(imageSrc && { src: imageSrc }),
    ...(imageId && { imageId }),
    ratio: displayType,
  };

  /**
   * Check if user's device supports activate/expansion
   * Supported devices are non-mobile/hover supported devices that are larger than SM breakpoint
   */
  const enableActivation = useMemo(() => {
    return !isMobile && !mediaMatchesSmDown;
  }, [mediaMatchesSmDown]);

  const activateTile = useCallback(() => {
    setIsActive(true);
    if (onActivate) {
      onActivate();
    }
  }, [onActivate]);

  const deactivateTile = useCallback(() => {
    setIsActive(false);
    onDeactivate();
  }, [onDeactivate]);

  const handleMouseOver = useCallback(() => {
    activateTile();
  }, [activateTile]);

  const handleMouseLeave = useCallback(() => {
    deactivateTile();
  }, [deactivateTile]);

  const tileImageContainer = (
    <div
      className={clsx(classes.imageContainer, 'prefers-reduced-motion', classes[`imageContainer-${displayType}`])}
    >
      <Image
        {...imageProps}
        imageSizes={{ all: 300 }}
      />
    </div>
  );

  return (
    <div
      className={clsx(classes.root)}
      aria-expanded={isActive}
      data-testid="tile"
      ref={tileRef}
    >
      <OdLink
        {...link}
        name={playIcon ? 'watch' : link.name}
        role="link"
        className={clsx(classes.link, { active: isActive })}
        /** link is tabable if tile is active or if it can't be activated */
        tabIndex={canBeFocused ? 0 : -1}
        onMouseOver={enableActivation ? handleMouseOver : undefined}
        onMouseLeave={enableActivation ? handleMouseLeave : undefined}
        onClick={onClick}
      >
        <div
          className={classes.tile}
        >
          <div
            className={clsx(
              'activableTile',
              classes.activableContent,
              // Using ':' in the class name prevents us from using it as a reference in another class.
              // See 'ctaButton' JSS definition above.
              classes[`activableContent-${displayType.replace(':', '_')}`],
            )}
          >
            {tileImageContainer}
            { /* render more content if tile can be activated */ }
            {enableActivation && (<TileMoreContent isActive={isActive} {...props}/>)}
            {statusBadge && (
              <Box className={classes.statusBadge}>
                {statusBadge}
              </Box>
            )}
            {ctaButton && (
              <div className={classes.ctaButton}>
                {cloneElement(ctaButton, {
                  tabIndex: isActive ? 0 : -1,
                })}
              </div>
            )}
          </div>
          <div className={classes.tileContent} aria-hidden={isActive}>
            <Typography variant="body1" component="h3" className={classes.tileTitle}>
              {title}
            </Typography>
            {
              subtitle && (
                <div className={classes.subtitle}>
                  {subtitle}
                </div>
              )
            }
          </div>
        </div>
      </OdLink>
    </div>
  );
};

interface TileProps {
  ref?: RefObject<HTMLDivElement>;
  className: string;
  onActivate: () => void;
  onDeactivate: () => void;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  canBeFocused?: boolean;
  classes?: any;
}

export const getTileBasedOnEntityType = (displayType: '16:9' | '2:3', tileItem: OnDemand2.CollectionItem, tileProps: TileProps) => {
  switch (tileItem.entityType) {
    case 'Movie':
    case 'Program':
    case 'Episode':
    case 'Clip':
      return (
        <VideoTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Series':
      return (
        <SeriesTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Collection':
      return (
        <CollectionTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    case 'Page':
      return (
        <PageTile
          collectionTileItem={tileItem}
          displayType={displayType}
          {...tileProps}
        />
      );
    default:
      return null;
  }
};

export default BaseTile;
