import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

import Logger from '@@utils/logger/Logger';

import { getAll as getAllProgress, record as recordProgress, Progresses } from '../services/ProgressService';
import type { AppThunk, RootState } from '../store';

export interface ProgressStoreState {
  ready: boolean;
  progresses: Progresses;
}

interface SetProgressPayload {
  videoId: string;
  seconds: number;
  percent: number;
  completed?: boolean;
}

interface RemoveProgressPayload {
  videoId: string;
}

const initialState: ProgressStoreState = {
  ready: false,
  progresses: {},
};

const progressStore = createSlice({
  name: 'ProgressStore',
  initialState,
  reducers: {
    setReady(state) {
      state.ready = true;
    },
    setProgress(state, action: PayloadAction<SetProgressPayload>) {
      const {
        videoId, seconds, completed = false, percent,
      } = action.payload;
      state.progresses[videoId] = { seconds, completed, percent };
    },
    setProgresses(state, action: PayloadAction<Progresses>) {
      state.progresses = action.payload;
    },
    removeProgress(state, action: PayloadAction<RemoveProgressPayload>) {
      const { videoId } = action.payload;
      delete state.progresses[videoId];
    },
    clear(state) {
      state.progresses = {};
    },
  },
});

export const getProgressStoreReady = createSelector(
  (state: RootState) => {
    return state.progress.ready;
  },
  (ready) => {
    return ready;
  },
);

export const getProgress = createSelector(
  (state: RootState) => {
    return state.progress.progresses;
  },
  (state, videoId) => {
    return videoId;
  },
  (progress, videoId) => {
    return get(progress, `${videoId}`, null);
  },
);

export const getResumePosition = createSelector(
  (state: RootState) => {
    return state.progress.progresses;
  },
  (_state, videoId) => {
    return videoId;
  },
  (progress, videoId) => {
    const progressData = get(progress, `${videoId}`, null);
    const completed = get(progressData, 'completed', false);
    const percent = get(progressData, 'percent', 0);
    let resumePosition = 0;

    if (completed === false && percent !== 100) {
      resumePosition = get(progressData, 'seconds', 0);
    }

    return resumePosition;
  },
);

export const getCompleted = createSelector(
  (state: RootState) => {
    return state.progress.progresses;
  },
  (state, videoId) => {
    return videoId;
  },
  (progress, videoId) => {
    return get(progress, `${videoId}.completed`, null);
  },
);

// async actions
export function setVideoProgress(videoId, position, percent) {
  return (dispatch) => {
    dispatch(progressStore.actions.setProgress({
      videoId,
      seconds: position,
      completed: false,
      percent,
    }));
    return recordProgress(videoId, position)
      .catch(() => {
        // there is an error, let's remove it from the store
        dispatch(progressStore.actions.removeProgress(videoId));
      });
  };
}

const handleGetAllProgress = (dispatch) => {
  return (progresses) => {
    dispatch(progressStore.actions.setProgresses(progresses));
    dispatch(progressStore.actions.setReady());
  };
};

const handleGetAllProgressError = (dispatch) => {
  return (error) => {
    dispatch(progressStore.actions.setReady());
    const errorMessage = get(error, 'message', 'error');
    Logger.error(errorMessage);
  };
};

/**
 * Load progress once using the existing promise if it exists.
 * Will refresh progresses from API each time.
 */
export function loadProgresses(): AppThunk<Promise<void>> {
  return (dispatch) => {
    return getAllProgress()
      .then(handleGetAllProgress(dispatch))
      .catch(handleGetAllProgressError(dispatch));
  };
}

/**
 * Load progress once using the existing promise if it exists.
 * If progresses were loaded previously, it will not refresh them.
 * To refresh progresses from the API, use loadProgresses above.
 */
export function loadProgressesOnce(): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.progress.ready) {
      return Promise.resolve();
    }

    return getAllProgress()
      .then(handleGetAllProgress(dispatch))
      .catch(handleGetAllProgressError(dispatch));
  };
}

export default progressStore;
export const { setProgress, removeProgress } = progressStore.actions;
