import { get } from 'lodash';
import { Duration } from 'luxon';

import { generatePathFromLinkProps } from '@@src/routes';
import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import { IMAGE_HOST, V3_API_HOST } from '@@utils/constants';

import { HeroRow, PageRowType, ShelfRow, ShortcutRow, PromoBlockRow } from '../components/PageRows/PageRow';
import { ShortcutItem } from '../components/Shelf/ShortcutShelf';
import { PartialCollection, transformCollectionToShelfOrGridRow, transformRecommendedCollectionToShelf } from './CatalogApiCollectionTransformer';
import {
  Availability,
  EntityType, getImageIdByProperties,
  getImageUrlByType,
  getRoute,
  transformAvailability, transformClassification,
  transformEntityType, transformEntityTypeToSeriesType, transformSponsorship,
} from './CatalogApiCommonTransformer';

export function transformPageSectionHeroCollectionToHeroRow(collection: components['schemas']['pageSectionHeroCollection']): HeroRow {
  const items = collection.items.map((item): OnDemand.Hero => {
    let { id } = item;

    if ('mpxMediaID' in item) {
      id = item.mpxMediaID.toString();
    }

    const route = getRoute(item);

    const type = transformEntityType(item.entityType as EntityType);

    let availability: Availability = {
      available: true,
      expired: false,
      availableDate: null,
      expiredDate: null,
    };

    if ('availability' in item) {
      availability = transformAvailability(item.availability);
    }

    let sponsorship = null;
    let cdpTitle = null;

    if ('sponsorships' in item) {
      sponsorship = transformSponsorship(item.sponsorships);
    }

    if ('cdpTitle' in item) {
      cdpTitle = item.cdpTitle;
    }

    const heroItem: Partial<OnDemand.TvSeries | OnDemand.Video> = {
      id,
      cdpTitle,
      title: item.title,
      mediaTitle: item.title,
      type,
      available: availability.available,
      expired: availability.expired,
      classification: 'classificationID' in item ? transformClassification(item.classificationID) : null,
      genres: 'genres' in item ? item.genres : [],
      isLiveStream: 'liveStream' in item ? item.liveStream : false,
      sponsorship,
      route,
      ...(item.entityType === 'TV_EPISODE' || item.entityType === 'SPORTS_EPISODE' || item.entityType === 'NEWS_EPISODE' ? {
        episodeData: {
          programName: item.seriesTitle,
          seasonNumber: item.seasonNumber,
          episodeNumber: item.episodeNumber,
          seriesId: item.seriesID,
          seriesSlug: item.seriesSlug,
          seasonSlug: item.seasonSlug,
          seriesType: transformEntityTypeToSeriesType(item.entityType),
          seriesEntityType: item.entityType.replace('_EPISODE', '_SERIES'),
          seriesRoute: {
            name: 'series',
            params: {
              seriesType: transformEntityTypeToSeriesType(item.entityType),
              slug: item.seriesSlug,
            },
          },
        },
      } : {}),
    };

    const featured = get(item, 'featured');
    if (featured && heroItem.type === 'TVSeries') {
      heroItem.featuredVideo = {
        title: featured.tagLine,
        // @todo simplify the hero item featured video's type post migration
        // @ts-ignore we don't need all of the SlimVideo attributes here
        video: {
          id: featured.mpxMediaID.toString(),
          title: featured.title,
          shortDescription: featured.description,
          duration: 'duration' in featured ? Duration.fromISO(featured.duration).as('seconds') : undefined,
          odImageId: get(featured, 'images.0.id'),
        },
      };
    }

    return {
      item: heroItem,
      title: item.title,
      tagLine: 'tagLine' in item ? item.tagLine : null,
      body: item.description,
      imageProps: {
        imageId: getImageIdByProperties(item.images, { ratio: '16:9', type: 'KEY_ART' }),
        alt: item.title,
        ratio: '16:9',
        imageSizes: { all: '100vw' },
      },
    };
  }).filter((item) => {
    // filter out unsupported items
    return !!item;
  });

  return {
    id: collection.id,
    name: collection.title,
    type: 'carousel',
    items,
  };
}

export function transformPageSectionCollectionToShortcutRow(collection: PartialCollection): ShortcutRow {
  const items = collection.items.map((item): ShortcutItem => {
    const route = getRoute(item);

    if (route) {
      return {
        name: item.title,
        ondemandUri: generatePathFromLinkProps(route),
        type: 'OndemandLink',
      };
    }

    return null;
  }).filter((item) => {
    return !!item;
  });

  return {
    id: collection.id,
    name: collection.title,
    type: 'shortcuts',
    items,
  };
}

export function transformPageSectionPromoBlockToPromoBlockRow(collection: components['schemas']['pageSectionPromoBlock'], pageSlug, adPos): PromoBlockRow {
  const promoBlockItem = get(collection, 'items.0');

  if (promoBlockItem) {
    let ad: PromoBlockRow['ad'];

    // only allow ad on the first position (which starts from 2)
    if (adPos === 2) {
      ad = {
        pageSlug,
        pos: adPos,
        targeting: {
          pos: adPos.toString(),
          section: pageSlug,
          pageType: 'index',
        },
      };
    }

    return {
      id: collection.id,
      name: null,
      type: 'promo-block',
      promo: {
        name: promoBlockItem.title,
        images: {
          xs: getImageUrlByType(promoBlockItem.images, 'PROMO_BLOCK_XS'),
          sm: getImageUrlByType(promoBlockItem.images, 'PROMO_BLOCK_SM'),
          md: getImageUrlByType(promoBlockItem.images, 'PROMO_BLOCK_MD'),
        },
        route: getRoute(promoBlockItem),
      },
      ad,
    };
  }

  return null;
}

export function transformPageSectionContinueWatchingToPageRow(collection: components['schemas']['pageSectionContinueWatching']): ShelfRow {
  return {
    name: collection.title,
    type: 'shelf',
    itemType: 'continue-watching',
    feedUrl: `${V3_API_HOST}/api/v3/video_continuewatching`,
    requireLogin: true,
  };
}

function transformPageSections(sections: components['schemas']['page']['sections'], pageSlug: string): PageRowType[] {
  // ad pos starts from 2 (requirement from ad sales)
  let adPos = 2;
  return sections
    .map((section) => {
      if (section.entityType === 'CURATED_COLLECTION' || section.entityType === 'DYNAMIC_COLLECTION' || section.entityType === 'PERSONALISED_COLLECTION') {
        if (section.displayType === 'SHORTCUT_BAR') {
          return transformPageSectionCollectionToShortcutRow(section);
        }

        let rowType;
        if (section.layout === 'GRID') {
          rowType = 'gridV2';
        } else if (section.layout === 'CAROUSEL') {
          if (section.displayType !== '1x1_MASK' && section.displayType !== 'TALL') {
            rowType = 'shelfV2';
          } else {
            rowType = 'shelf';
          }
        }

        if (section.displayType === 'LIVE_SCHEDULE') {
          rowType = 'shelf';
        }

        if (section.entityType === 'PERSONALISED_COLLECTION') {
          let shelfDisplayType;
          if (section.displayType === '16x9') {
            shelfDisplayType = '16:9';
          } else {
            shelfDisplayType = '2:3';
          }
          return transformRecommendedCollectionToShelf(section, rowType, shelfDisplayType, section?.recommendationID, section?.recommendationVariant);
        }

        return transformCollectionToShelfOrGridRow(section, rowType, section?.recommendationID, section?.recommendationVariant);
      }

      if (section.entityType === 'HERO_COLLECTION') {
        return transformPageSectionHeroCollectionToHeroRow(section);
      }

      if (section.entityType === 'CONTINUE_WATCHING') {
        return transformPageSectionContinueWatchingToPageRow(section);
      }

      if (section.entityType === 'PROMO_BLOCK') {
        const promoBlock = transformPageSectionPromoBlockToPromoBlockRow(section, pageSlug, adPos);
        adPos += 1;
        return promoBlock;
      }

      return null;
    })
    .filter((pageSection) => {
      // filter out unsupported entity types
      return !!pageSection;
    });
}

const transform = (pageData: components['schemas']['page']): OnDemand.Page => {
  const heroImage = {};
  let ogImage;

  ['16:9', '2:3'].forEach((ratio) => {
    const imageId = getImageIdByProperties(pageData.images, { type: 'KEY_ART', ratio });
    if (imageId) {
      heroImage[ratio] = `${IMAGE_HOST}/${imageId}`;
    }
  });

  const imageId = getImageIdByProperties(pageData.images, { type: 'BANNER', ratio: '16:9' });
  if (imageId) {
    ogImage = `${IMAGE_HOST}/${imageId}`;
  }

  const rows = transformPageSections(pageData.sections, pageData.slug);

  // inject newsletter promoblock on the 9th row on the home page
  if (pageData.slug === 'home') {
    rows.splice(
      8,
      0,
      {
        feedUrl: null,
        itemType: null,
        name: null,
        requireLogin: true,
        type: 'newsletter-promo-block',
      },
    );
  }

  const sponsorship = transformSponsorship(pageData.sponsorships);

  return {
    id: pageData.id,
    type: 'Page',
    title: pageData.title,
    description: pageData.description,
    keywords: '',
    rows,
    route: {
      to: generatePathFromLinkProps(getRoute(pageData)),
    },
    heroImage,
    ogImage,
    sponsorship,
  };
};

export default { transform };
