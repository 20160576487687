import {
  get, has, camelCase, map, indexOf, find, last, capitalize,
} from 'lodash';
import { DateTime } from 'luxon';
import slugify from 'slugify';

import { videoIdsToBeServedInOldUrl } from '@@src/pages/VideoPage';
import { escapeRegExp, getTypeByGenres } from '@@utils/helpers';
import Logger from '@@utils/logger/Logger';

import OnDemand from '../@types/OnDemand';
import { RouteParams } from '../@types/route';
import { FeedItem } from '../apis/FeedApi';
import { ImageProps } from '../components/Html/Image';
import { OdLinkProps } from '../routes';

class Transformer<D, TR> {
  private _fields = {};

  // @todo: use typescript 4
  public field(name: string): Transformer<D, TR>;
  public field(name: string, path): Transformer<D, TR>;
  public field<R = any>(name: string, transformer: (data: D) => R): Transformer<D, TR>;
  public field<R = any>(name: string, dependencies: string[], transformer: (data: D) => R): Transformer<D, TR>;
  public field<R = any>(name: string, dependencies: string[], transformer: (data: D, dep1: any) => R): Transformer<D, TR>;
  public field<R = any>(name: string, dependencies: string[], transformer: (data: D, dep1: any, dep2: any) => R): Transformer<D, TR>;
  public field<R = any>(name: string, dependencies: string[], transformer: (data: D, dep1: any, dep2: any, dep3: any) => R): Transformer<D, TR>;
  public field<R = any>(name: string, dependencies: string[], transformer: (data: D, dep1: any, dep2: any, dep3: any, dep4: any) => R): Transformer<D, TR>;

  public field(name, dependencies = null, transformer = null) {
    let path;
    let _transformer = transformer;
    let _dependencies = dependencies;

    if (arguments.length === 2) {
      path = dependencies || name;
      _dependencies = null;
      _transformer = typeof path === 'function' ? path : (data) => {
        return get(data, path);
      };
    }

    this._fields[name] = { dependencies: _dependencies || [], transformer: _transformer };
    return this;
  }

  public transform<T = TR>(data: D, fields: string[]): T {
    let item = null;
    fields.forEach((field) => {
      if (!item) item = {};
      let deps = [];
      if (this._fields[field].dependencies.length) {
        deps = Object.values(this.transform(data, this._fields[field].dependencies));
      }
      item[field] = this._fields[field].transformer(data, ...deps);
    });
    return item;
  }

  public get fields() {
    return this._fields;
  }

  public extends(source) {
    Object.keys(source.fields).forEach((key) => {
      this._fields[key] = source.fields[key];
    });
    return this;
  }
}

const getSeriesTitle = (feedItem: FeedItem.Any): string => {
  return get(feedItem, 'partOfSeries.name');
};

const getTitle = (feedItem: FeedItem.Any): string => {
  if (feedItem.type === 'Episode') {
    let episodeTitle = get(feedItem, 'displayTitles.videoPlayer.title');
    // strip series title from video title
    const separatorRegex = '[:]';
    const pattern = new RegExp(`^${escapeRegExp(getSeriesTitle(feedItem))}${separatorRegex}*`);
    episodeTitle = episodeTitle.replace(pattern, '').trim();

    // if episode title is blank, fallback to display title double title
    if (!episodeTitle) {
      return get(feedItem, 'displayTitles.double.title');
    }

    return episodeTitle;
  }

  if (feedItem.type === 'Clip'
    || feedItem.type === 'OneOff'
    || feedItem.type === 'Movie'
  ) {
    return feedItem.displayTitles.double.title;
  }

  return feedItem.name;
};

const getNumberOfSeasons = (feedItem: FeedItem.Any): number => {
  if (
    feedItem.type === 'TVSeries'
    || feedItem.type === 'Episode'
  ) {
    return get(feedItem, 'containSeasons', []).length;
  }

  return null;
};

const getSeriesAvailability = (feedItem: FeedItem.Any) => {
  if (feedItem.type !== 'TVSeries') {
    return null;
  }

  const seasons = get(feedItem, 'containSeasons', []);
  if (seasons.length === 0) {
    return { availabilityStarts: null, availabilityEnds: null };
  }

  let startDate;
  let endDate;

  seasons.forEach((season) => {
    const seasonStartDate = DateTime.fromJSDate(new Date(season.offer.availabilityStarts));
    const seasonEndDate = DateTime.fromJSDate(new Date(season.offer.availabilityEnds));

    if (!startDate || seasonStartDate < startDate) {
      startDate = seasonStartDate;
    }

    if (!endDate || seasonEndDate > endDate) {
      endDate = seasonEndDate;
    }
  });

  if (startDate && endDate) {
    return {
      availabilityStarts: startDate.toISO(),
      availabilityEnds: endDate.toISO(),
    };
  }

  return null;
};

const getSeriesSeasonsData = (feedItem: FeedItem.Any): OnDemand.V3SeriesSeasonData[] => {
  if (feedItem.type === 'TVSeries') {
    const seasons: any[] = get(feedItem, 'containSeasons', []);
    if (seasons.length === 0) {
      return [];
    }

    return seasons.map<OnDemand.V3SeriesSeasonData>((season) => {
      const seasonNumber = get(season, 'seasonNumber');
      return {
        name: season.name,
        seasonNumber: seasonNumber ? parseInt(seasonNumber, 10) : null,
        availabilityStarts: DateTime.fromJSDate(new Date(season.offer.availabilityStarts)).toISO(),
        availabilityEnds: DateTime.fromJSDate(new Date(season.offer.availabilityEnds)).toISO(),
        numberOfEpisodesAvailable: get(season, 'numberOfEpisodesAvailable', 0),
      };
    });
  }

  return null;
};

const getVideoIdFromUrl = (url: string): string => {
  return url ? url.split('/').pop() : null;
};

const baseTransformer = new Transformer<FeedItem.Any, any>();
baseTransformer
  .field('id', (feedItem: FeedItem.Any) => {
    return getVideoIdFromUrl(feedItem.id);
  })

  .field('entityType', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'externalRelations.sbsondemand.entity_type');
  })

  .field('catalogueId', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'externalRelations.sbsondemand.id');
  })

  .field('type', 'feedItem.type')

  .field('title', (feedItem: FeedItem.Any): string => {
    return getTitle(feedItem);
  })

  // this is used in the page title tag
  .field('pageTitle', ['genres'], (feedItem: FeedItem.Any, genres): string => {
    let pageTitle = get(feedItem, 'displayTitles.pageTitle');

    if (pageTitle) {
      return pageTitle;
    }

    pageTitle = getTitle(feedItem);
    const { type } = feedItem;

    if (type === 'TVSeries') {
      const genre = get(genres, '0');
      if (genre) {
        pageTitle += ` | ${genre}`;
      }
    }

    return pageTitle;
  })

  .field('mediaTitle', (feedItem: FeedItem.Any): string => {
    return feedItem.name;
  })

  .field('description', (feedItem: FeedItem.Any): string => {
    return feedItem.description || '';
  })

  .field('shortDescription', (feedItem: FeedItem.Any): string => {
    // fallback to description if shortDescription is not available
    let shortDescription = feedItem.shortDescription || feedItem.description || '';

    if (shortDescription.length > 250) {
      shortDescription = `${shortDescription.substring(0, 250)}...`;
    }

    return shortDescription;
  })

  // this is used in the page meta description
  .field('metaDescription', (feedItem: FeedItem.Any): string => {
    const description = feedItem.shortDescription || feedItem.description || '';
    return description.replace(/(\r\n|\n|\r)/gm, ' ');
  })

  .field('genres', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.genre') && feedItem.taxonomy.genre !== null ? feedItem.taxonomy.genre.map((o) => {
      return o.name.toLowerCase().charAt(0).toUpperCase() + o.name.slice(1);
    }) : [];
  })

  .field('subgenres', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.subGenre') && feedItem.taxonomy.subGenre !== null ? feedItem.taxonomy.subGenre.map((o) => {
      return o.name.toLowerCase().charAt(0).toUpperCase() + o.name.slice(1);
    }) : [];
  })

  .field('groupings', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.grouping') && feedItem.taxonomy.grouping !== null ? feedItem.taxonomy.grouping.map((o) => {
      return o.name;
    }) : [];
  })

  .field('tones', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.tone') && feedItem.taxonomy.tone !== null ? feedItem.taxonomy.tone.map((o) => {
      return o.name;
    }) : [];
  })

  .field('eras', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.era') && feedItem.taxonomy.era !== null ? feedItem.taxonomy.era.map((o) => {
      return o.name;
    }) : [];
  })

  .field('themes', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.theme') && feedItem.taxonomy.theme !== null ? feedItem.taxonomy.theme.map((o) => {
      return o.name;
    }) : [];
  })

  .field('subjects', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.subject') && feedItem.taxonomy.subject !== null ? feedItem.taxonomy.subject.map((o) => {
      return o.name;
    }) : [];
  })

  .field('organisations', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.organisation') && feedItem.taxonomy.organisation !== null ? feedItem.taxonomy.organisation.map((o) => {
      return o.name;
    }) : [];
  })

  .field('places', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.place') && feedItem.taxonomy.place !== null ? feedItem.taxonomy.place.map((o) => {
      // @TODO: In the future, API will return places as an object. This is just an interim solution while it's being fixed.
      return typeof o === 'string' ? o.trim() : o.name;
    }) : [];
  })

  .field('locations', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.location') && feedItem.taxonomy.location !== null ? feedItem.taxonomy.location.map((o) => {
      return o.name;
    }) : [];
  })

  .field('slug', (feedItem: FeedItem.Any): string => {
    if (feedItem.slug) {
      return feedItem.slug;
    }

    return slugify(feedItem.name, {
      lower: true,
      strict: true,
    });
  })

  .field('route', ['id', 'slug', 'genres', 'isLiveStream'], (feedItem: FeedItem.Any, id, slug, genres, isLiveStream): OdLinkProps => {
    const routeParams: RouteParams = {};
    let routeName;

    switch (feedItem.type) {
      case 'TVSeries':
        routeName = 'series';
        routeParams.slug = slug;
        if (get(feedItem, 'externalRelations.sbsondemand.entity_type')) {
          routeParams.seriesType = get(feedItem, 'externalRelations.sbsondemand.entity_type').replace('_SERIES', '').toLowerCase();
        } else {
          routeParams.seriesType = getTypeByGenres(genres);
        }
        break;
      case 'OneOff':
        routeName = 'program';
        routeParams.id = id;
        routeParams.slug = slug;
        routeParams.programType = getTypeByGenres(genres);
        break;
      case 'Clip':
        if (isLiveStream) {
          routeName = 'video';
          routeParams.id = id;
          routeParams.slug = slug;
        } else {
          routeName = 'watch';
          routeParams.id = id;
        }
        break;
      case 'Episode':
        routeName = 'seriesEpisode';
        routeParams.id = id;
        routeParams.seriesSlug = feedItem.partOfSeries.slug;
        routeParams.episodeSlug = slug;
        routeParams.seriesType = getTypeByGenres(genres);
        break;
      case 'Movie':
        routeName = 'movie';
        routeParams.id = id;
        routeParams.slug = slug;
        break;

      case 'Collection':
        routeName = 'collection';
        routeParams.id = slug;
        break;

      /* istanbul ignore next */
      default:
        Logger.error('Unable to generate route for unknown feed item type', {
          type: feedItem.type,
        });
        return null;
    }

    // for movie / oneoff if the video ids is to be served in the old url, return the old video route
    if (['OneOff', 'Movie'].includes(feedItem.type) && videoIdsToBeServedInOldUrl.includes(id)) {
      routeName = 'video';
      routeParams.id = id;
      routeParams.slug = slug;
    }

    return {
      name: routeName,
      params: routeParams,
    };
  })

  .field('images', (feedItem: FeedItem.Any): OnDemand.VideoImages | OnDemand.TvSeriesImages => {
    return feedItem.thumbnails.reduce((thumbnails, thumbnail) => {
      const key = camelCase(thumbnail.name.trim());
      return {
        ...thumbnails,
        [key]: thumbnail.contentUrl,
      };
    }, { default: feedItem.thumbnailUrl });
  })

  .field('classification', (feedItem: FeedItem.Any): string => {
    if (has(feedItem, 'contentRating')) {
      return feedItem.contentRating.toUpperCase();
    }

    return null;
  })

  .field('consumerAdvices', (feedItem: FeedItem.Any): string[] => {
    if (feedItem.type === 'TVSeries' && has(feedItem, 'contentAdvisory')) {
      return feedItem.contentAdvisory;
    }

    if ((feedItem.type === 'Clip'
      || feedItem.type === 'OneOff'
      || feedItem.type === 'Movie'
      || feedItem.type === 'Episode'
    ) && has(feedItem, 'consumerAdvice')) {
      return feedItem.consumerAdvice;
    }

    return [];
  })

  .field('consumerAdviceTexts', (): string[] => {
    // not supported in v3, so returning an empty array
    return [];
  })

  .field('sbsSubCertification', (feedItem: FeedItem.Any): OnDemand.SbsSubCertification => {
    return feedItem.sbsSubCertification;
  })

  .field('expiredDate', (feedItem: FeedItem.Any): string => {
    // v3 expired date comes from season end date in redback which is not reliable, we will assume that it doesn't have an expiry date
    if (feedItem.type === 'TVSeries') {
      return null;
    }

    const availabilityEnds = get(feedItem, 'offer.availabilityEnds', null);

    if (availabilityEnds) {
      return DateTime.fromJSDate(new Date(availabilityEnds)).toUTC().toISO();
    }

    return null;
  })

  .field('availableDate', (feedItem: FeedItem.Any): string => {
    // v3 expired date comes from season start date in redback which is not reliable, we will assume that it doesn't have an available date
    if (feedItem.type === 'TVSeries') {
      return null;
    }

    return DateTime.fromJSDate(new Date(get(feedItem, 'offer.availabilityStarts', null))).toUTC().toISO();
  })

  .field('expired', ['expiredDate'], (_feedItem: FeedItem.Any, expiredDate: string): boolean => {
    if (expiredDate) {
      return DateTime.now() > DateTime.fromISO(expiredDate);
    }

    return false;
  })

  .field('available', ['availableDate', 'expired'], (_feedItem: FeedItem.Any, availableDate: string, expired: boolean): boolean => {
    // By default, always available
    let availableDateReached = true;

    if (availableDate) {
      availableDateReached = DateTime.now() > DateTime.fromISO(availableDate);
    }

    return !expired && availableDateReached;
  })

  .field('type', (feedItem: FeedItem.Any) => {
    return feedItem.type;
  })

  .field('hasClosedCaption', (feedItem: FeedItem.Any): boolean => {
    return feedItem.visualAids.closedCaptions.length > 0
      || feedItem.visualAids.subtitles.length > 0;
  })

  .field('isHighDefinition', (feedItem: FeedItem.Any): boolean => {
    return feedItem.videoFormat === 'HD';
  })

  .field('publicationYear', (feedItem: FeedItem.Any): string => {
    if ('datePublished' in feedItem) {
      const datePublished = DateTime.fromISO(feedItem.datePublished);
      return datePublished.toFormat('yyyy');
    }

    return null;
  })

  // coming from MPX pubDate or publication[type=BroadCastEvent] in v3
  .field('airDate', (feedItem: FeedItem.Any): string => {
    if ('publication' in feedItem && feedItem.publication.type === 'BroadcastEvent') {
      return DateTime.fromISO(feedItem.publication.startDate).toUTC().toISO();
    }

    return null;
  })

  .field('useType', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'taxonomy.useType', '');
  })

  .field('isLiveStream', ['useType'], (feedItem: FeedItem.Any, useType) => {
    if (feedItem.type === 'TVSeries') {
      return false;
    }
    return useType === 'Live Stream';
  })

  .field('keywords', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'keywords') && feedItem.keywords !== null ? feedItem.keywords : [];
  })

  .field('award', (feedItem: FeedItem.Any): string => {
    return get(feedItem, 'award', '');
  })

  .field('pilatId', (feedItem: FeedItem.Any) => {
    if (feedItem.type === 'Movie' || feedItem.type === 'OneOff' || feedItem.type === 'Episode' || feedItem.type === 'Clip') {
      const pilatId = get(feedItem, 'pilat.id', null);
      return pilatId ? pilatId.split('/').pop() : null;
    }
    return null;
  })

  .field('pilatDealcode', (feedItem: FeedItem.Any) => {
    if (feedItem.type === 'Movie' || feedItem.type === 'OneOff' || feedItem.type === 'Episode' || feedItem.type === 'Clip') {
      const pilatDealcode = get(feedItem, 'externalRelations.pilat.deal.id', null);
      return pilatDealcode ? pilatDealcode.split('/').pop() : null;
    }
    return null;
  })

  .field('seriesCrid', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'externalRelations.tvAnytime.series.crid', null);
  })

  .field('channels', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.channel') && feedItem.taxonomy.channel !== null ? feedItem.taxonomy.channel.map((o) => {
      return o.name;
    }) : [];
  })

  .field('collections', (feedItem: FeedItem.Any): string[] => {
    return has(feedItem, 'taxonomy.collection') && feedItem.taxonomy.collection !== null ? feedItem.taxonomy.collection.map((o) => {
      return o.name;
    }) : [];
  })

  // country of origin
  .field('countries', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'country.name') ? [feedItem.country.name] : [];
  })

  .field('duration', 'duration')

  .field('languages', (feedItem: FeedItem.Any) => {
    return map(get(feedItem, 'inLanguage', []), (language) => {
      return language.name;
    });
  })

  .field('directors', (feedItem: FeedItem.Any) => {
    return map(get(feedItem, 'director', []), (director) => {
      return director.name;
    });
  })

  .field('cast', (feedItem: FeedItem.Any) => {
    return map(get(feedItem, 'actor', []), (actor) => {
      return actor.name;
    });
  })

  .field('subtitles', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'visualAids.subtitles');
  })

  .field('closedCaptions', (feedItem: FeedItem.Any) => {
    return get(feedItem, 'visualAids.closedCaptions', []);
  })

  .field('trailerId', (feedItem: FeedItem.Any) => {
    return getVideoIdFromUrl(get(feedItem, 'trailers[0].id'));
  })

  // add episodeData
  .field('episodeData', ['genres'], (feedItem: FeedItem.Any, genres): OnDemand.EpisodeData => {
    if (feedItem.type === 'Episode') {
      const seasonNumber = get(feedItem, 'partOfSeason.seasonNumber');
      return {
        seriesId: feedItem.partOfSeries.id.split('/').pop(),
        seriesSlug: feedItem.partOfSeries.slug,
        programName: feedItem.partOfSeries.name,
        seasonNumber: seasonNumber ? parseInt(seasonNumber, 10) : null,
        episodeNumber: feedItem.episodeNumber,
        seasonSlug: seasonNumber ? `season-${parseInt(seasonNumber, 10)}` : null,
        seriesType: getTypeByGenres(genres),
        seriesEntityType: `${getTypeByGenres(genres).toUpperCase()}_SERIES`,
        seriesRoute: {
          name: 'series',
          params: {
            seriesType: getTypeByGenres(genres),
            slug: feedItem.partOfSeries.slug,
          },
        },
      };
    }

    return null;
  })

  .field('playerTitles', (feedItem: FeedItem.Any): OnDemand.PlayerTitles => {
    return {
      title: get(feedItem, 'displayTitles.title'),
      subtitle: get(feedItem, 'displayTitles.subtitle'),
    };
  })

  .field('requiresSubscription', (feedItem: FeedItem.Any) => {
    return feedItem.type === 'TVSeries' || get(feedItem, 'requiresSubscription', false);
  })
  .field('numberOfSeasons', (feedItem) => {
    return getNumberOfSeasons(feedItem);
  });

export function transformToVideoItem<T extends FeedItem.Video, R extends OnDemand.Video>(feedItem: T): R {
  return baseTransformer.transform(feedItem, [
    'id', 'catalogueId', 'title', 'pageTitle', 'description', 'shortDescription', 'metaDescription', 'images',
    'duration', 'genres', 'subgenres', 'expiredDate', 'expired', 'availableDate', 'available',
    'isLiveStream', 'publicationYear', 'airDate', 'countries',
    'classification', 'consumerAdvices', 'consumerAdviceTexts', 'hasClosedCaption', 'isHighDefinition', 'languages', 'subtitles', 'closedCaptions',
    'directors', 'cast', 'route', 'trailerId', 'type', 'requiresSubscription',
    'episodeData', 'groupings', 'tones', 'eras', 'themes', 'subjects', 'organisations', 'places', 'locations', 'channels',
    'useType', 'keywords', 'collections', 'channels', 'award', 'pilatId', 'pilatDealcode', 'seriesCrid',
    'mediaTitle', 'playerTitles', 'numberOfSeasons', 'sbsSubCertification',
  ]);
}

const slimVideoFields = [
  'id', 'title', 'description', 'shortDescription', 'images', 'duration',
  'classification', 'requiresSubscription', 'expired', 'available', 'availableDate',
  'isLiveStream', 'route', 'consumerAdvices', 'consumerAdviceTexts', 'mediaTitle', 'expiredDate', 'type',
  'sbsSubCertification',
];

export function transformToSlimVideoItem(feedItem: FeedItem.Video): OnDemand.SlimVideo {
  return baseTransformer.transform(feedItem, slimVideoFields);
}

const featuredVideoTransformer = new Transformer<FeedItem.Video, OnDemand.SlimVideo>();
featuredVideoTransformer.extends(baseTransformer);
featuredVideoTransformer
  .field('title', (feedItem) => {
    let featuredTitle = '';
    const title = getTitle(feedItem);

    if (feedItem.type === 'Episode') {
      featuredTitle += get(feedItem, 'displayTitles.components.seasonEpisodeAbbreviated');
    }
    featuredTitle += (featuredTitle ? ': ' : '') + title;

    return featuredTitle;
  });

export function transformToFeaturedVideoItem(feedItem: FeedItem.Video): OnDemand.SlimVideo {
  return featuredVideoTransformer.transform(feedItem, slimVideoFields);
}

export function transformToSlimEpisodeItem(feedItem: FeedItem.Episode): OnDemand.SlimEpisode {
  return baseTransformer.transform(feedItem, slimVideoFields.concat(['episodeData']));
}

const seriesTransformer = new Transformer<FeedItem.TvSeries, OnDemand.TvSeries>();
seriesTransformer.extends(baseTransformer);
seriesTransformer
  .field('numberOfSeasons', (feedItem) => {
    return getNumberOfSeasons(feedItem);
  })
  .field('featuredEpisode', (feedItem) => {
    const featuredEpisodeData = get(feedItem, 'featuredEpisodeData', null);
    return featuredEpisodeData ? transformToVideoItem(featuredEpisodeData) : null;
  })
  .field('availability', (feedItem) => {
    return getSeriesAvailability(feedItem);
  })
  .field('seasonsData', (feedItem) => {
    return getSeriesSeasonsData(feedItem);
  });

export function transformToSeriesItem(feedItem: FeedItem.TvSeries): OnDemand.TvSeries {
  return seriesTransformer.transform(feedItem, [
    'id', 'title', 'slug', 'pageTitle', 'description', 'metaDescription', 'images', 'route',
    'genres', 'subgenres', 'countries', 'numberOfSeasons',
    'classification', 'consumerAdvices', 'consumerAdviceTexts', 'hasClosedCaption', 'isHighDefinition', 'languages', 'subtitles',
    'directors', 'cast', 'trailerId', 'type', 'featuredEpisode',
    'availability', 'seasonsData', 'available', 'expired',
  ]);
}

const itemFieldTransformFn = (feedItem: FeedItem.Any) => {
  if (feedItem.type === 'TVSeries') {
    return transformToSeriesItem(feedItem);
  }

  if (feedItem.type === 'Episode' || feedItem.type === 'OneOff' || feedItem.type === 'Movie' || feedItem.type === 'Clip') {
    return transformToVideoItem(feedItem);
  }

  return null;
};

const tileTransformer = new Transformer<FeedItem.Any, OnDemand.Tile>();
tileTransformer.extends(baseTransformer);
tileTransformer
  .field('item', itemFieldTransformFn)
  .field('title', getTitle)

  .field('metadata', ['genres', 'subgenres', 'useType'], (feedItem, genres, subgenres, useType) => {
    if (useType === 'Trailer') {
      return ['Trailer'];
    }

    if (feedItem.type === 'Episode') {
      return [getSeriesTitle(feedItem)];
    }

    if (!genres || genres.length === 0) {
      return [];
    }

    // for sport genre, use the subgenre if exists
    const sportGenreIndex = indexOf(genres, 'Sport');
    if (sportGenreIndex >= 0) {
      const subGenre = find(subgenres, (v) => {
        return v.match(/^Sport/);
      });

      if (subGenre) {
        return [last(subGenre.split('/'))];
      }

      return [genres[sportGenreIndex]];
    }

    return [get(genres, '0')];
  })

  .field('extraMetadata', ['publicationYear'], (feedItem, publicationYear) => {
    const extraMetadata = [];

    if (feedItem.type === 'Movie' || feedItem.type === 'OneOff') {
      if (publicationYear) {
        extraMetadata.push(publicationYear);
      }
    }

    if (feedItem.type === 'Episode') {
      if (has(feedItem, 'partOfSeason.seasonNumber') && has(feedItem, 'episodeNumber')) {
        const { seasonNumber } = feedItem.partOfSeason;
        // 4 digit season will not have S prefix
        if (seasonNumber.length >= 4) {
          extraMetadata.push(`${seasonNumber} E${feedItem.episodeNumber}`);
        } else {
          extraMetadata.push(`S${seasonNumber} E${feedItem.episodeNumber}`);
        }
      }
    }

    if (feedItem.type === 'TVSeries') {
      const numberOfSeasons = getNumberOfSeasons(feedItem);
      if (numberOfSeasons > 0) {
        extraMetadata.push({ key: 'metadata.seasonsAvailable', params: { count: numberOfSeasons } });
      }
    }

    return extraMetadata;
  });

const landscapeTileTransformer = new Transformer<FeedItem.Any, OnDemand.Tile>();
landscapeTileTransformer.extends(tileTransformer);
landscapeTileTransformer
  .field('imageProps', ['item', 'images'], (_feedItem, item, images) => {
    let src = images.default;
    if (images.thumbnailFullHdWithTitle) {
      src = images.thumbnailFullHdWithTitle;
    } else if (images.thumbnailLarge) {
      src = images.thumbnailLarge;
    }

    return {
      src,
      alt: item.title,
      title: item.title,
      ratio: '16:9',
      srcSetWidths: [300, 600],
      imageSizes: { all: 300 },
    };
  });

export function transformToLandscapeTileItem(feedItem: FeedItem.Any): OnDemand.Tile<OnDemand.Video | OnDemand.TvSeries> {
  return landscapeTileTransformer.transform(feedItem, [
    'item', 'metadata', 'extraMetadata',
    'imageProps', 'mediaTitle',
  ]);
}

const portraitTileTransformer = new Transformer<FeedItem.Any, OnDemand.Tile>();
portraitTileTransformer.extends(tileTransformer);
portraitTileTransformer
  .field('imageProps', ['item', 'images'], (_feedItem, item, images): ImageProps => {
    return {
      src: images.moviePoster2X,
      alt: item.title,
      title: item.title,
      ratio: '2:3',
      srcSetWidths: [300, 600],
      imageSizes: { all: 300 },
    };
  });

export function transformToPortraitTileItem(feedItem: FeedItem.Any): OnDemand.Tile<OnDemand.Video | OnDemand.TvSeries> {
  return portraitTileTransformer.transform(feedItem, [
    'item', 'metadata', 'extraMetadata',
    'imageProps', 'mediaTitle',
  ]);
}

const continueWatchingTileTransformer = new Transformer<FeedItem.Any, OnDemand.Tile>();
continueWatchingTileTransformer.extends(landscapeTileTransformer);
continueWatchingTileTransformer
  .field('item', (feedItem) => {
    const item = itemFieldTransformFn(feedItem);
    item.title = get(feedItem, 'displayTitles.double.title');
    return item;
  })

  .field('metadata', (feedItem) => {
    // make this title case
    return [get(feedItem, 'displayTitles.double.subtitle', '').split(' ').map(capitalize).join(' ')];
  })
  .field('extraMetadata', () => {
    return [];
  });

export function transformToContinueWatchingTileItem(feedItem: FeedItem.Any): OnDemand.Tile {
  return continueWatchingTileTransformer.transform(feedItem, [
    'item', 'metadata', 'extraMetadata', 'imageProps',
  ]);
}

const heroTransformer = new Transformer<FeedItem.Any, OnDemand.Hero>();
heroTransformer.extends(baseTransformer);
heroTransformer
  .field('item', itemFieldTransformFn)
  .field('title', 'displayTitles.hero.title')
  .field('tagLine', 'displayTitles.hero.caption')
  .field('body', 'displayTitles.hero.description')
  .field('imageProps', ['images'], (feedItem, images): ImageProps => {
    return {
      src: images.hero,
      ratio: '16:9',
      imageSizes: { all: '100vw' },
      alt: get(feedItem, 'title'),
    };
  });

export default {
  transformToPortraitTileItem,
  transformToLandscapeTileItem,
  transformToContinueWatchingTileItem,
  transformToVideoItem,
  transformToSeriesItem,
  transformToFeaturedVideoItem,
  transformToSlimVideoItem,
  transformToSlimEpisodeItem,
};
