import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import { httpClientWithAuth } from '@@utils/HttpClient';
import { FAVOURITE_ADD_PATH, FAVOURITE_LIST_PATH, FAVOURITE_REMOVE_PATH, FAVOURITE_FEED_PATH } from '@@utils/constants';

import { transformToLandscapeTileItem } from '../transformers/FeedTransformer';
import { FeedApi } from './FeedApi';

interface AddFavouriteResponse {
  add: {
    response: {
      result: boolean;
      detail: {
        type: 'program' | 'movie';
        itemId: string;
      };
    };
    status: boolean;
  };
}

export function add(type: 'program' | 'movie', id: string): Promise<boolean> {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);
  return httpClientWithAuth.post(FAVOURITE_ADD_PATH, formData)
    .then((response: AxiosResponse<AddFavouriteResponse>) => {
      return response.data.add.response.result;
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

interface RemoveFavouriteResponse {
  remove: {
    response: {
      result: boolean;
    };
    status: boolean;
  };
}

export function remove(type: 'program' | 'movie', id: string): Promise<boolean> {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);

  return httpClientWithAuth.post(FAVOURITE_REMOVE_PATH, formData)
    .then((response: AxiosResponse<RemoveFavouriteResponse>) => {
      return get(response, 'data.remove.response.result', false);
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

interface ListAllFavouritesResponse {
  all: {
    response: {
      movies: string[];
      programs: string[];
    };
    status: boolean;
  };
}

export function list() {
  return httpClientWithAuth.get(FAVOURITE_LIST_PATH)
    .then((response: AxiosResponse<ListAllFavouritesResponse>) => {
      let series = get(response, 'data.all.response.programs', []);

      series = series.concat(get(response, 'data.all.response.programs_fda', []));

      return {
        videos: get(response, 'data.all.response.movies', []),
        series,
      };
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

export function fetchFavouriteItems(page: number = 1, pageSize: number = 30) {
  let options = null;

  if (pageSize) {
    const start = (page - 1) * pageSize + 1;
    const end = start + (pageSize - 1);
    const range = `${start}-${end}`;
    options = {
      params: {
        range,
      },
    };
  }

  return httpClientWithAuth.get(FAVOURITE_FEED_PATH, options)
    .then((response: AxiosResponse<FeedApi.Response>) => {
      const feedItems = get(response, 'data.itemListElement');
      const numberOfItems = get(response, 'data.numberOfItems');
      const totalNumberOfItems = get(response, 'data.totalNumberOfItems');
      const previousNumberOfItems = (page - 1) * pageSize;

      const favouriteItems = feedItems.filter((feedItem) => {
        return !!feedItem.id;
      }).map((feedItem) => {
        return transformToLandscapeTileItem(feedItem);
      });

      return {
        items: favouriteItems,
        hasMoreItems: previousNumberOfItems + numberOfItems < totalNumberOfItems,
      };
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

export default {
  add,
  remove,
  list,
  fetchFavouriteItems,
};
