// we can't use object destructuring here because the process.env[KEY] will be replaced during build
/* eslint-disable prefer-destructuring */

export const PROXY = process.env.PROXY;

export const VIDEO_CONFIG_PATH = '/api/v3/video_config';
export const SEARCH_PATH = '/catalogue';
export const VIDEO_STREAM_PATH = '/api/v3/video_stream';
export const VIDEO_RELATED_PATH = '/api/v3/video_recommendations/relatedvideos';
export const PROGRAM_LOOKUP_PATH = '/api/v3/video_program?slug=[SLUG]';
export const PROGRAM_LOOKUP_BY_ID_PATH = '/api/v3/video_program?id=[ID]';
export const VIDEO_LOOKUP_PATH = '/api/v3/video_detail?id=[VIDEOID]';
export const FAVOURITE_ADD_PATH = '/api/v3/video_favourite/add';
export const FAVOURITE_REMOVE_PATH = '/api/v3/video_favourite/remove';
export const FAVOURITE_LIST_PATH = '/api/v3/video_favourite/all';
export const FAVOURITE_FEED_PATH = '/api/v3/video_favourite/feed';
export const PROGRESS_RECORD_PATH = '/api/v3/video_progress/record';
export const PROGRESS_GETALL_PATH = '/api/v3/video_progress/all';
export const PROGRESS_REMOVE_FROM_CONTINUE_WATCHING_PATH = '/api/v3/video_progress/removefromcontinuewatching';

// User profile
export const NEWSLETTER_SUBSCRIPTIONS_PATH = '/newsletters';

export const VERSION = process.env.VERSION;
export const BUILD_HASH = process.env.BUILD_HASH;

export const CATALOGUE_MEDIA_BY_MPX_ID = '/mpx-media/{mpxMediaID}';
export const CATALOGUE_TV_SERIES_BY_SLUG = '/tv-series/{slug}';
export const CATALOGUE_NEWS_SERIES_BY_SLUG = '/news-series/{slug}';
export const CATALOGUE_SPORTS_SERIES_BY_SLUG = '/sports-series/{slug}';
export const CATALOGUE_PAGE_BY_SLUG = '/pages/{slug}';
export const COLLECTION_BY_SLUG = '/collections/{slug}';
export const CATALOGUE_DOCUMENTS = '/documents/{name}';
export const PLAYBACK_STREAM_BY_MPX_ID = '/stream/{id}';

export const ADNXS_API_HOST = 'https://ib.adnxs.com';
export const ADNXS_API_HEADER_BIDDING_PATH = '/prebid/lfv';

// shared build time variables
export const IMAGE_HOST = process.env.BVAR_IMAGE_HOST;
export const IMAGE_RESIZER_URL = process.env.BVAR_IMAGE_RESIZER_URL;
export const V3_API_HOST = process.env.BVAR_V3_API_HOST;
export const MYACCOUNT_HOST = process.env.BVAR_MYACCOUNT_HOST;
export const CHROMECAST_RCV_APP_ID = process.env.BVAR_CHROMECAST_RCV_APP_ID;
export const CHROMECAST_RCV_APP_V3_ID = process.env.BVAR_CHROMECAST_RCV_APP_V3_ID;
export const BASENAME = process.env.BASENAME;
export const WEB_HOST = typeof window !== 'undefined' ? `${document.location.protocol}//${document.location.host}` : process.env.BVAR_WEB_HOST;
