import { FunctionComponent, ReactElement } from 'react';

import PageRow, { PageRowType } from './PageRow';

export interface PageRowsProps {
  rows: PageRowType[];
}

const PageRows: FunctionComponent<PageRowsProps> = ({ rows }) => {
  return (
    <div>
      {
        rows.map((row, index): ReactElement => {
          const key = row.id;
          return (
            <PageRow key={key} row={row} rowIndex={index}/>
          );
        })
      }
    </div>
  );
};

export default PageRows;
