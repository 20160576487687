import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import HttpProxyAgent from 'http-proxy-agent';
import HttpsProxyAgent from 'https-proxy-agent';

import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';
import Logger from '@@utils/logger/Logger';
import Browser from '@@utils/newrelicAgent/Browser';

import { getSessionId } from '../components/Login/LoginForm';
import { ADNXS_API_HOST, PROXY, V3_API_HOST } from './constants';

export function createHttpClient(_options: AxiosRequestConfig) {
  const options = {
    ..._options,
  };

  if (PROXY) {
    options.httpAgent = new HttpProxyAgent(PROXY);
    options.httpsAgent = new HttpsProxyAgent(PROXY);
  }

  return axios.create(options);
}

const v3ApiOptions: AxiosRequestConfig = {
  baseURL: V3_API_HOST,
  params: {
    context: 'odwebsite',
  },
};

const removeQueryStringParameterFromUrl = (url, name) => {
  if (url.match(/(http(s)?):\/\/.*?\?.*/)) {
    const newUrl = new URL(url);
    const { searchParams } = newUrl;
    searchParams.delete(name);
    newUrl.search = searchParams.toString();
    return newUrl.toString();
  }

  return url;
};

/**
 * v3 api http client
 */
const httpClient = createHttpClient(v3ApiOptions);
httpClient.interceptors.request.use((_axiosConfig) => {
  const axiosConfig = { ..._axiosConfig };

  // remove context from the url so it's not duplicated by the context defined in config params
  axiosConfig.url = removeQueryStringParameterFromUrl(axiosConfig.url, 'context');

  return axiosConfig;
});

/**
 * v3 api http client with auth
 */
const httpClientWithAuth = createHttpClient(v3ApiOptions);
httpClientWithAuth.interceptors.request.use((_axiosConfig) => {
  const axiosConfig = { ..._axiosConfig };

  // remove context from the url so it's not duplicated by the context defined in config params
  axiosConfig.url = removeQueryStringParameterFromUrl(axiosConfig.url, 'context');

  const token = getSessionId();
  if (token) {
    axiosConfig.headers.Authorization = `Bearer ${token}`;
  }

  return axiosConfig;
}, (error) => {
  return Promise.reject(error);
});

/**
 * catalogue http client
 * @param language
 */
export function catalogueHttpClient(language: string) {
  return createHttpClient({
    baseURL: process.env.BVAR_CATALOGUE_API_HOST,
    headers: {
      'Accept-Language': language,
      ...(
        process.env.BVAR_CATALOGUE_API_KEY ? {
          'X-Api-Key': process.env.BVAR_CATALOGUE_API_KEY,
        } : null
      ),
    },
  });
}

/**
 * catalogue http client with Auth
 * @param language
 */
export function catalogueHttpClientWithAuth(language: string) {
  const instance = createHttpClient({
    baseURL: process.env.BVAR_CATALOGUE_API_HOST,
    headers: {
      'Accept-Language': language,
      ...(
        process.env.BVAR_CATALOGUE_API_KEY ? {
          'X-Api-Key': process.env.BVAR_CATALOGUE_API_KEY,
        } : null
      ),
    },
  });

  instance.interceptors.request.use(async (_axiosConfig) => {
    const axiosConfig = { ..._axiosConfig };
    const sbsLogin = await getSbsLoginInstance();
    return sbsLogin.getAccessToken()
      .then((accessToken) => {
        if (accessToken) {
          axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return axiosConfig;
      });
  });

  return instance;
}

/**
 * playback http client
 */
export function playbackHttpClient(language: string) {
  const instance = createHttpClient({
    baseURL: process.env.BVAR_PLAYBACK_API_HOST,
    headers: {
      'Accept-Language': language,
    },
  });

  instance.interceptors.request.use(async (_axiosConfig) => {
    const axiosConfig = { ..._axiosConfig };

    const sbsLogin = await getSbsLoginInstance();
    return sbsLogin.getAccessToken()
      .then((accessToken) => {
        axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
        return axiosConfig;
      });
  });
  return instance;
}

/**
 * epg http client
 */
const epgHttpClient = createHttpClient({
  baseURL: process.env.BVAR_EPG_API_HOST,
});

/**
 * content search http client
 */
export function contentSearchHttpClient() {
  return createHttpClient({
    baseURL: process.env.BVAR_CONTENT_SEARCH_API_HOST,
    headers: {
      ...(
        process.env.BVAR_CATALOGUE_API_KEY ? {
          'X-Api-Key': process.env.BVAR_CATALOGUE_API_KEY,
        } : null
      ),
    },
  });
}

const adnxsHttpClient = createHttpClient({
  baseURL: ADNXS_API_HOST,
});

export default httpClient;

/**
 * Helper function to log http error
 * @param axiosError axios error object
 * @param error javascript error object
 * @param level error level for the log.
 */
export function logHttpError(
  axiosError: AxiosError,
  error: Error,
  level: 'warn' | 'error' = 'error',
) {
  // log with the given error level
  Logger[level](error, {
    error: {
      message: axiosError.message,
      request: axiosError.request ? {
        host: axiosError.request.host,
        path: axiosError.request.path,
      } : null,
      response: axiosError.response ? {
        data: axiosError.response.data,
        status: axiosError.response.status,
      } : null,
    },
  });

  Browser.addPageAction('HttpRequestError', {
    platform: 'web',
    ...(window && {
      source: window.location.href,
    }),
    errorMessage: error.message,
    requestMethod: axiosError.config.method,
    requestBaseUrl: axiosError.config.baseURL,
    requestPath: axiosError.config.url.replace(axiosError.config.baseURL, ''),
    requestParams: axiosError.config.params,
    requestErrorMessage: axiosError.message,
  });
}

/**
 * Helper function to handle http error, it will log the error and perform additional actions depending on errorLevel.
 * If the response status code is in the ignore list, it will return `ignoreReturnValue`
 * @param sourceError axios error object
 * @param error javascript error object
 * @param additionalAttributes additional attributes to pass on to the logger
 * @param ignoreStatusCodes the status codes that will be ignored
 * @param ignoreReturnValue the value returned if the status code is ignored
 * @param errorLevel error level for the log. If 'error' then re-throw the exception after logging otherwise record in NR
 */
export function handleHttpError<R>(
  sourceError: AxiosError,
  error: Error,
  additionalAttributes: Record<string, unknown> = {},
  ignoreStatusCodes: number[] = [],
  ignoreReturnValue: R = null,
  errorLevel: 'warn' | 'error' = 'error',
) {
  if (!('config' in sourceError)) {
    throw sourceError;
  }

  // return the `ignoreReturnValue` if the response error code is in the ignore list
  if (sourceError.response && ignoreStatusCodes.includes(sourceError.response.status)) {
    return ignoreReturnValue;
  }

  // log with the given error level
  Logger[errorLevel](error, {
    error: {
      message: sourceError.message,
      request: sourceError.request ? {
        host: sourceError.request.host,
        path: sourceError.request.path,
      } : null,
      response: sourceError.response ? {
        data: sourceError.response.data,
        status: sourceError.response.status,
      } : null,
    },
    ...additionalAttributes,
  });

  if (errorLevel === 'error') {
    // re-throw the error
    throw (sourceError);
  }

  Browser.addPageAction('HttpRequestError', {
    platform: 'web',
    ...(window && {
      source: window.location.href,
    }),
    errorMessage: error.message,
    requestMethod: sourceError.config.method,
    requestBaseUrl: sourceError.config.baseURL,
    requestPath: sourceError.config.url.replace(sourceError.config.baseURL, ''),
    requestParams: sourceError.config.params,
    requestErrorMessage: sourceError.message,
  });
  return null;
}

export {
  httpClient,
  httpClientWithAuth,
  epgHttpClient,
  adnxsHttpClient,
};
